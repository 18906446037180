import React, { Component, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';
import { getIsPreviewStatus } from '../../state/app';
import PropTypes from 'prop-types';
import axios from 'axios';
import Highlighter from 'react-highlight-words';
import orderBy from 'lodash/orderBy';
import { SvgIcon } from './Common/SvgIcon';
import { useGeneralSettings } from '../Hooks/GeneralSettings';
import PageLoadingData from './Common/PageLoadingData';
import { ContentfulBlockWackySearch } from './ContentfulBlockWackySearch';

const contentful = require('contentful');

const client = contentful.createClient({
     space: process.env.CONTENTFUL_SPACE_ID,
     accessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
     environment: process.env.GATSBY_CONTENTFUL_PREVIEW_ENVIRONMENT
});

// highlightIndex: Index of matched text
const Highlight = ({ children, highlightIndex }) => <span style={{ fontWeight: 'bold', fontFamily: 'gotham' }}>{children}</span>;

class PreviewEmployerGroupsSearching extends Component {
     constructor(props) {
          super(props);

          this.state = {
               employerGroups: [],
               suggestions: [],
               employerGroupsURL: this.props.groupTypeToTarget === 'Agency' ? process.env.GROUP_LANDING_PAGE_AGENCY_URL : process.env.GROUP_LANDING_PAGE_URL,
               searchInputText: '',
               groupURLSelected: '',
               partnerships: null,
               partnerSolutionUrl: process.env.PARTNER_SOLUTIONS_URL + 'pet/',
               cobrandedGroupLinks: null
          };
     }

     componentDidMount() {
          client
               .getEntries({
                    content_type: 'employerGroups',
                    'fields.searchable[in]': true,
                    'fields.groupType[exists]': true, // Get entries that have value for Group Type field
                    'fields.groupDisplayName[exists]': true,
                    order: 'fields.groupDisplayName',
                    limit: 0
               })
               .then(async (response) => {
                    let newGroups = [];
                    for (let i = 0; i <= response.total / 1000; i++) {
                         const getEntries = client.getEntries({
                              content_type: 'employerGroups',
                              'fields.searchable[in]': true,
                              'fields.groupType[exists]': true, // Get entries that have value for Group Type field
                              'fields.groupDisplayName[exists]': true,
                              order: 'fields.groupDisplayName',
                              limit: 1000,
                              skip: 1000 * i
                         });
                         newGroups.push(getEntries);
                    }

                    Promise.all(newGroups)
                         .then((data) => {
                              let newEmployerGroup = [];
                              data.forEach(({ items }) => {
                                   let newEmployerGroups = [];
                                   if (this.props.groupTypeToTarget != 'All' && this.props.groupTypeToTarget != null) {
                                        newEmployerGroups = items.filter(({ fields: group }) => group.groupType === this.props.groupTypeToTarget);
                                   }
                                   newEmployerGroup = newEmployerGroup.concat(newEmployerGroups);
                              });
                              this.setState({ employerGroups: newEmployerGroup });
                         })
                         .catch(console.error);
               })
               .catch(console.error);

          client
               .getEntries({
                    content_type: 'generalSettings',
                    'fields.includeNationwideContent[in]': true
               })
               .then((response) => {
                    if (response.items.length) {
                         const query = `query($searchable: Boolean, $partnerTypeValues: [String],$offset: Int, $limit: Int) {
                              petHealthInsuranceSingleProductAutomationCmCollection(where: { searchable: $searchable, partnerType_in: $partnerTypeValues }, limit:$limit, skip:$offset) {
                                   items {
                                        sys {
                                             id
                                        }
                                        spid
                                        thirdpartyId
                                        partnerType
                                        partnershipName
                                   }
                                   total
                              }
                              dualProductAutomationCmCollection(where: { searchable: $searchable, partnerType_in: $partnerTypeValues }, limit:$limit, skip:$offset) {
                                   items {
                                        sys {
                                             id
                                        }
                                        spid
                                        thirdpartyId
                                        partnerType
                                        partnershipName
                                   }
                                   total
                              }
                              vetHelpLineSingleProductAutomationCmCollection(where: { searchable: $searchable, partnerType_in: $partnerTypeValues }, limit:$limit, skip:$offset) {
                                   items {
                                        sys {
                                             id
                                        }
                                        spid
                                        thirdpartyId
                                        partnerType
                                        partnershipName
                                   }
                                   total
                              }
                         }`;
                         let dataSearch = {
                              query,
                              variables: `{"searchable":true,"partnerTypeValues":["Voluntary Benefits", "Strategic", "Member Specialty Group"], "offset":0, "limit":1000}`
                         };

                         if (this.props.groupTypeToTarget === 'Agency') {
                              dataSearch = {
                                   query,
                                   variables: '{\r\n  "searchable":true,\r\n  "partnerTypeValues":["Agency Channel"]\r\n, \r\n  "offset":0, \r\n  "limit":1000}'
                              };
                         }

                         const dataLimit = 1000;
                         const fetchData = async (query, variables, accessToken, spaceId, environment) => {
                              const dataSearch = {
                                   query,
                                   variables: JSON.stringify({ ...variables })
                              };
                              try {
                                   const response = await axios.post(`https://graphql.contentful.com/content/v1/spaces/${spaceId}/environments/${environment}`, dataSearch, {
                                        headers: {
                                             'Content-Type': 'application/json',
                                             Authorization: `Bearer ${accessToken}`
                                        }
                                   });

                                   return response.data.data;
                              } catch (error) {
                                   console.error('Error fetching data:', error);
                                   throw error;
                              }
                         };

                         axios.post(
                              `https://graphql.contentful.com/content/v1/spaces/${process.env.EP_CONTENTFUL_SPACE_ID}/environments/${process.env.EP_CONTENTFUL_ENVIRONMENT}`,
                              dataSearch,
                              {
                                   headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${process.env.EP_CONTENTFUL_ACCESS_TOKEN}`
                                   }
                              }
                         )
                              .then(async (data) => {
                                   const petHealthInsurance = data.data.data.petHealthInsuranceSingleProductAutomationCmCollection?.items || [];
                                   const dualProduct = data.data.data.dualProductAutomationCmCollection?.items || [];
                                   const vetHelpLine = data.data.data.vetHelpLineSingleProductAutomationCmCollection?.items || [];

                                   const totalPetHealthInsurance = data.data.data.petHealthInsuranceSingleProductAutomationCmCollection?.total;
                                   const totalDualProduct = data.data.data.dualProductAutomationCmCollection?.total;
                                   const totalVetHelpLine = data.data.data.vetHelpLineSingleProductAutomationCmCollection?.total;

                                   const processBatch = async (offset, limit, total, collectionName) => {
                                        const collectionItems = [];
                                        for (let i = offset; i < total; i += limit) {
                                             const batchData = await fetchData(
                                                  query,
                                                  { ...JSON.parse(dataSearch.variables), offset: i, limit },
                                                  process.env.EP_CONTENTFUL_ACCESS_TOKEN,
                                                  process.env.EP_CONTENTFUL_SPACE_ID,
                                                  process.env.EP_CONTENTFUL_ENVIRONMENT
                                             );
                                             collectionItems.push(...(batchData[collectionName]?.items || []));
                                        }
                                        return collectionItems;
                                   };

                                   const processedPetHealthInsurance =
                                        totalPetHealthInsurance >= dataLimit
                                             ? await processBatch(0, dataLimit, totalPetHealthInsurance, 'petHealthInsuranceSingleProductAutomationCmCollection')
                                             : petHealthInsurance;

                                   const processedDualProduct =
                                        totalDualProduct >= dataLimit ? await processBatch(0, dataLimit, totalDualProduct, 'dualProductAutomationCmCollection') : dualProduct;

                                   const processedVetHelpLine =
                                        totalVetHelpLine >= dataLimit
                                             ? await processBatch(0, dataLimit, totalVetHelpLine, 'vetHelpLineSingleProductAutomationCmCollection')
                                             : vetHelpLine;

                                   const partnerships = [...processedPetHealthInsurance, ...processedDualProduct, ...processedVetHelpLine];
                                   const partnershipsConvert = partnerships.map((item) => {
                                        return {
                                             fields: {
                                                  groupType: item.partnerType,
                                                  groupDisplayName: item.partnershipName,
                                                  slug: item.thirdpartyId,
                                                  legend: 'EP Asset'
                                             }
                                        };
                                   });
                                   this.setState({ partnerships: partnershipsConvert });
                              })
                              .catch((error) => {
                                   console.log('error => ', error);
                              });
                    }
               })
               .catch(console.error);
     }

     onSearchInputTextChanged = (text) => this.setState({ searchInputText: text });

     // Teach Autosuggest how to calculate suggestions for any given input value.
     getSuggestions = (value) => {
          const inputValue = value.trim().toLowerCase();
          const inputLength = inputValue.length;
          if (inputLength < 3) return [];

          const employerGroupsFilter = this.state.employerGroups.filter(({ fields: group }) => {
               // const displayName = group.alternateSearchTerm ?
               //      `${group.groupDisplayName} (${group.alternateSearchTerm.alternateSearchTerm})`.toLowerCase()
               //      : group.groupDisplayName.toLowerCase();

               let checkAlternate = false;
               if (group.alternateSearchTerm) {
                    checkAlternate = group.alternateSearchTerm.toLowerCase().includes(inputValue);
               }

               const displayName = group.groupDisplayName.toLowerCase();

               return displayName.toLowerCase().includes(inputValue) || checkAlternate;
          });

          const partnershipsFilter = this.state.partnerships?.filter(({ fields: partner }) => partner.groupDisplayName.toLowerCase().includes(inputValue)) || [];

          const searchResults = [...employerGroupsFilter, ...partnershipsFilter].sort(function(a, b) {
               if (a.fields.groupDisplayName < b.fields.groupDisplayName) {
                    return -1;
               }
               if (a.fields.groupDisplayName > b.fields.groupDisplayName) {
                    return 1;
               }
               return 0;
          });

          return searchResults;
     };

     // When suggestion is clicked, Autosuggest needs to populate the input
     // based on the clicked suggestion. Teach Autosuggest how to calculate the
     // input value for every given suggestion.
     getSuggestionValue = ({ fields: suggestion }) => {
          // GDN: Group Display Name
          const displayName = suggestion.groupDisplayName;
          return displayName;
     };

     // Use your imagination to render suggestions.
     renderSuggestion = ({ fields: suggestion }) => {
          const displayName = suggestion.groupDisplayName;
          const slug = suggestion.legend !== 'EP Asset' ? this.state.employerGroupsURL + suggestion.slug : this.state.partnerSolutionUrl + suggestion.slug;

          return (
               <a href={slug} target="_blank">
                    <Highlighter searchWords={[this.state.searchInputText]} autoEscape={true} textToHighlight={displayName} highlightTag={Highlight} />
               </a>
          );
     };

     onChange = (event, { newValue }) => {
          this.setState({ searchInputText: newValue });
     };

     // Autosuggest will call this function every time you need to update suggestions.
     // You already implemented this logic above, so just use it.
     onSuggestionsFetchRequested = ({ value }) => {
          this.setState({ suggestions: this.getSuggestions(value) });
     };

     // Autosuggest will call this function every time you need to clear suggestions.
     onSuggestionsClearRequested = () => {
          this.setState({ suggestions: [] });
     };

     onSuggestionHighlighted = ({ suggestion }) => {
          if (suggestion) {
               const slug =
                    suggestion.fields.legend !== 'EP Asset' ? this.state.employerGroupsURL + suggestion.fields.slug : this.state.partnerSolutionUrl + suggestion.fields.slug;
               this.setState({ slug });
          } else {
               this.setState({ groupURLSelected: '' });
          }
     };

     onKeyDown = (e) => {
          if (e.keyCode == '13') {
               if (typeof window != 'undefined') {
                    if (this.state.groupURLSelected) {
                         window.open(this.state.groupURLSelected, '_blank');
                    } else {
                         if (this.state.suggestions.length == 1) {
                              const slug =
                                   suggestions[0].fields.legend !== 'EP Asset'
                                        ? `${this.state.employerGroupsURL}${this.state.suggestions[0].fields.slug}`
                                        : `${this.state.partnerSolutionUrl}${suggestions[0].fields.slug}`;
                              window.open(slug, '_blank');
                         } else {
                              if (this.props.groupTypeToTarget === 'Agency') {
                                   window.location.href = `/myagent/results/?q=${encodeURIComponent(this.state.searchInputText)}`;
                              } else {
                                   window.location.href = `/employee-benefits/company-search/results/?q=${encodeURIComponent(this.state.searchInputText)}`;
                              }
                         }
                    }
               }
          }
     };

     searchIconClicked = () => {
          if (typeof window != 'undefined' && this.state.searchInputText) {
               if (this.props.groupTypeToTarget === 'Agency') {
                    window.location.href = `/myagent/results/?q=${encodeURIComponent(this.state.searchInputText)}`;
               } else {
                    window.location.href = `/employee-benefits/company-search/results/?q=${encodeURIComponent(this.state.searchInputText)}`;
               }
          }
     };

     render() {
          const inputProps = {
               placeholder: this.props.inputPlaceholder,
               value: this.state.searchInputText,
               onChange: this.onChange,
               onKeyDown: this.onKeyDown,
               'aria-label': 'Your company name',
               disabled: this.state.employerGroups.length <= 0
          };

          return (
               <div id="custom-search" className="custom-search">
                    {this.props.searchTitle && (
                         <div className="custom-search-title">
                              <p>
                                   <strong>{this.props.searchTitle}</strong>
                              </p>
                         </div>
                    )}
                    <div className="custom-search-input">
                         <Autosuggest
                              suggestions={this.state.suggestions}
                              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              onSuggestionHighlighted={this.onSuggestionHighlighted}
                              inputProps={inputProps}
                         />
                         {this.props.searchIcon && (
                              <div className="search-icon" onClick={this.searchIconClicked}>
                                   <SvgIcon type="search" />
                              </div>
                         )}
                    </div>
               </div>
          );
     }
}

const EmployerGroupsSearching = (props) => {
     const [searchInputText, setSearchInputText] = useState('');
     const [suggestions, setSuggestions] = useState([]);
     const [groupURLSelected, setGroupURLSelected] = useState('');
     const [partnerships, setPartnerships] = useState(null);
     const [employerGroups, setEmployerGroups] = useState([]);
     const [cobrandedGroupLinks, setCobrandedGroupLinks] = useState(null);

     const employerGroupsURL = props.groupTypeToTarget === 'Agency' ? process.env.GROUP_LANDING_PAGE_AGENCY_URL : process.env.GROUP_LANDING_PAGE_URL;
     const partnerSolutionUrl = process.env.PARTNER_SOLUTIONS_URL + 'pet/';
     const dataLimit = 1000;
     const generalContentId = process.env.CONTENTFUL_CONTENT_JSON;

     useEffect(() => {
          //Get Cobranded Links
          client
               .getAsset(generalContentId)
               .then(async (entry) => {
               const { data } = await axios.get(entry.fields.file.url);
               setCobrandedGroupLinks(data);
          }).catch(console.error);
          //Get Employer Group
          client
               .getEntries({
                    content_type: 'employerGroups',
                    'fields.searchable[in]': true,
                    'fields.groupType[exists]': true, // Get entries that have value for Group Type field
                    'fields.groupDisplayName[exists]': true,
                    order: 'fields.groupDisplayName',
                    limit: 0
               })
               .then(async (response) => {
                    let newGroups = [];
                    for (let i = 0; i <= response.total / 1000; i++) {
                         const getEntries = client.getEntries({
                              content_type: 'employerGroups',
                              'fields.searchable[in]': true,
                              'fields.groupType[exists]': true, // Get entries that have value for Group Type field
                              'fields.groupDisplayName[exists]': true,
                              order: 'fields.groupDisplayName',
                              limit: 1000,
                              skip: 1000 * i
                         });
                         newGroups.push(getEntries);
                    }

                    Promise.all(newGroups)
                         .then((data) => {
                              let newEmployerGroup = [];
                              data.forEach(({ items }) => {
                                   let newEmployerGroups = [];
                                   if (props.groupTypeToTarget != 'All' && props.groupTypeToTarget != null) {
                                        newEmployerGroups = items
                                             .filter(({ fields: group }) => group.groupType === props.groupTypeToTarget)
                                             .map(({ fields: group }) => ({
                                                  node: group
                                             }));
                                   }
                                   newEmployerGroup = newEmployerGroup.concat(newEmployerGroups);
                              });
                              setEmployerGroups(newEmployerGroup);
                         })
                         .catch(console.error);
               })
               .catch(console.error);

          client
               .getEntries({
                    content_type: 'generalSettings',
                    'fields.includeNationwideContent[in]': true
               })
               .then((response) => {
                    if (response.items.length) {
                         const query = `query($searchable: Boolean, $partnerTypeValues: [String],$offset: Int, $limit: Int) {
                              petHealthInsuranceSingleProductAutomationCmCollection(where: { searchable: $searchable, partnerType_in: $partnerTypeValues }, limit:$limit, skip:$offset) {
                                   items {
                                        sys {
                                             id
                                        }
                                        spid
                                        thirdpartyId
                                        partnerType
                                        partnershipName
                                        altSearchItem
                                        coBranded
                                        coBrand
                                   }
                                   total
                              }
                              dualProductAutomationCmCollection(where: { searchable: $searchable, partnerType_in: $partnerTypeValues }, limit:$limit, skip:$offset) {
                                   items {
                                        sys {
                                             id
                                        }
                                        spid
                                        thirdpartyId
                                        partnerType
                                        partnershipName
                                        altSearchItem
                                        coBranded
                                        coBrand
                                   }
                                   total
                              }
                              vetHelpLineSingleProductAutomationCmCollection(where: { searchable: $searchable, partnerType_in: $partnerTypeValues }, limit:$limit, skip:$offset) {
                                   items {
                                        sys {
                                             id
                                        }
                                        spid
                                        thirdpartyId
                                        partnerType
                                        partnershipName
                                        altSearchItem
                                   }
                                   total
                              }
                         }`;
                         let dataSearch = {
                              query,
                              variables: `{"searchable":true,"partnerTypeValues":["Voluntary Benefits", "Strategic", "Member Specialty Group"], "offset":0, "limit":1000}`
                         };

                         if (props.groupTypeToTarget === 'Agency') {
                              dataSearch = {
                                   query,
                                   variables: '{\r\n  "searchable":true,\r\n  "partnerTypeValues":["Agency Channel"]\r\n, \r\n  "offset":0, \r\n  "limit":1000}'
                              };
                         }

                         const fetchData = async (query, variables, accessToken, spaceId, environment) => {
                              const dataSearch = {
                                   query,
                                   variables: JSON.stringify({ ...variables })
                              };
                              try {
                                   const response = await axios.post(`https://graphql.contentful.com/content/v1/spaces/${spaceId}/environments/${environment}`, dataSearch, {
                                        headers: {
                                             'Content-Type': 'application/json',
                                             Authorization: `Bearer ${accessToken}`
                                        }
                                   });

                                   return response.data.data;
                              } catch (error) {
                                   console.error('Error fetching data:', error);
                                   throw error;
                              }
                         };

                         axios.post(
                              `https://graphql.contentful.com/content/v1/spaces/${process.env.EP_CONTENTFUL_SPACE_ID}/environments/${process.env.EP_CONTENTFUL_ENVIRONMENT}`,
                              dataSearch,
                              {
                                   headers: {
                                        'Content-Type': 'application/json',
                                        Authorization: `Bearer ${process.env.EP_CONTENTFUL_ACCESS_TOKEN}`
                                   }
                              }
                         )
                              .then(async (data) => {
                                   const petHealthInsurance = data.data.data.petHealthInsuranceSingleProductAutomationCmCollection?.items || [];
                                   const dualProduct = data.data.data.dualProductAutomationCmCollection?.items || [];
                                   const vetHelpLine = data.data.data.vetHelpLineSingleProductAutomationCmCollection?.items || [];

                                   const totalPetHealthInsurance = data.data.data.petHealthInsuranceSingleProductAutomationCmCollection?.total;
                                   const totalDualProduct = data.data.data.dualProductAutomationCmCollection?.total;
                                   const totalVetHelpLine = data.data.data.vetHelpLineSingleProductAutomationCmCollection?.total;

                                   const processBatch = async (offset, limit, total, collectionName) => {
                                        const collectionItems = [];
                                        for (let i = offset; i < total; i += limit) {
                                             const batchData = await fetchData(
                                                  query,
                                                  { ...JSON.parse(dataSearch.variables), offset: i, limit },
                                                  process.env.EP_CONTENTFUL_ACCESS_TOKEN,
                                                  process.env.EP_CONTENTFUL_SPACE_ID,
                                                  process.env.EP_CONTENTFUL_ENVIRONMENT
                                             );
                                             collectionItems.push(...(batchData[collectionName]?.items || []));
                                        }
                                        return collectionItems;
                                   };

                                   const processedPetHealthInsurance =
                                        totalPetHealthInsurance >= dataLimit
                                             ? await processBatch(0, dataLimit, totalPetHealthInsurance, 'petHealthInsuranceSingleProductAutomationCmCollection')
                                             : petHealthInsurance;

                                   const processedDualProduct =
                                        totalDualProduct >= dataLimit ? await processBatch(0, dataLimit, totalDualProduct, 'dualProductAutomationCmCollection') : dualProduct;

                                   const processedVetHelpLine =
                                        totalVetHelpLine >= dataLimit
                                             ? await processBatch(0, dataLimit, totalVetHelpLine, 'vetHelpLineSingleProductAutomationCmCollection')
                                             : vetHelpLine;

                                   const partnerships = [...processedPetHealthInsurance, ...processedDualProduct, ...processedVetHelpLine];
                                   const partnershipsConvert = partnerships.map((item) => {
                                        return {
                                             node: {
                                                  groupType: item.partnerType,
                                                  groupDisplayName: item.partnershipName,
                                                  slug: item.thirdpartyId,
                                                  legend: 'EP Asset',
                                                  alternateSearchTerm: item.altSearchItem,
                                                  coBranded: item.coBranded,
                                                  coBrand: item.coBrand
                                             }
                                        };
                                   });
                                   setPartnerships(partnershipsConvert);
                              })
                              .catch((error) => {
                                   console.log('error => ', error);
                              });
                    }
               })
               .catch(console.error);
     }, []);

     // Teach Autosuggest how to calculate suggestions for any given input value.
     const getSuggestions = (value) => {
          const inputValue = value.trim().toLowerCase();
          const inputLength = inputValue.length;
          if (inputLength < 3) return [];

          const employerGroupsFilter = employerGroups.filter(({ node: group }) => {
               let checkAlternate = false;
               if (group.alternateSearchTerm) {
                    const alternateSearchTerm = group.alternateSearchTerm;
                    checkAlternate = alternateSearchTerm.toLowerCase().includes(inputValue);
               }

               const displayName = group.groupDisplayName.toLowerCase();

               return displayName.toLowerCase().includes(inputValue) || checkAlternate;
          });

          const partnershipsFilter = partnerships?.filter(({ node: partner }) =>{
               let checkAlternate = false;
               if (partner.alternateSearchTerm) {
                    const alternateSearchTerm = partner.alternateSearchTerm;
                    checkAlternate = alternateSearchTerm.toLowerCase().includes(inputValue);
               }
               
               const displayName = partner.groupDisplayName.toLowerCase();
               
               return displayName.toLowerCase().includes(inputValue) || checkAlternate;
          });
           
          const searchResults = [...employerGroupsFilter, ...partnershipsFilter].sort(function(a, b) {
               if (a.node.groupDisplayName < b.node.groupDisplayName) {
                    return -1;
               }
               if (a.node.groupDisplayName > b.node.groupDisplayName) {
                    return 1;
               }
               return 0;
          });

          return searchResults;
     };

     // When suggestion is clicked, Autosuggest needs to populate the input
     // based on the clicked suggestion. Teach Autosuggest how to calculate the
     // input value for every given suggestion.
     const getSuggestionValue = ({ node: suggestion }) => suggestion.groupDisplayName;

     // Use your imagination to render suggestions.
     const renderSuggestion = ({ node: suggestion }) => {
          const displayName = suggestion.groupDisplayName;
          const slug = suggestion.legend !== 'EP Asset' ? employerGroupsURL + suggestion.slug : 
          (suggestion.coBranded ? cobrandedGroupLinks.cobranded[suggestion.coBrand] : partnerSolutionUrl) + suggestion.slug;
          return (
               <a href={slug} target="_blank">
                    <Highlighter searchWords={[searchInputText]} autoEscape={true} textToHighlight={displayName} highlightTag={Highlight} />
               </a>
          );
     };

     const onChange = (event, { newValue }) => {
          setSearchInputText(newValue);
     };

     // Autosuggest will call this function every time you need to update suggestions.
     // You already implemented this logic above, so just use it.
     const onSuggestionsFetchRequested = ({ value }) => {
          setSuggestions(getSuggestions(value));
     };

     // Autosuggest will call this function every time you need to clear suggestions.
     const onSuggestionsClearRequested = () => {
          setSuggestions([]);
     };

     const onSuggestionHighlighted = ({ suggestion }) => {
          if (suggestion) {
               const slug = suggestion.node.legend !== 'EP Asset' ? employerGroupsURL + suggestion.node.slug : partnerSolutionUrl + suggestion.node.slug;
               setGroupURLSelected(slug);
          } else {
               setGroupURLSelected('');
          }
     };

     const onKeyDown = (e) => {
          if (e.keyCode == '13') {
               if (typeof window != 'undefined') {
                    if (groupURLSelected) {
                         window.open(groupURLSelected, '_blank');
                    } else {
                         if (suggestions.length == 1) {
                              const slug =
                                   suggestions[0].node.legend !== 'EP Asset'
                                        ? `${employerGroupsURL}${suggestions[0].node.slug}`
                                        : `${partnerSolutionUrl}${suggestions[0].node.slug}`;
                              window.open(slug, '_blank');
                         } else {
                              if (props.groupTypeToTarget === 'Agency') {
                                   window.location.href = `/myagent/results/?q=${encodeURIComponent(searchInputText)}`;
                              } else {
                                   window.location.href = `/employee-benefits/company-search/results/?q=${encodeURIComponent(searchInputText)}`;
                              }
                         }
                    }
               }
          }
     };

     const inputProps = {
          placeholder: props.inputPlaceholder,
          value: searchInputText,
          onChange: onChange,
          onKeyDown: onKeyDown,
          'aria-label': 'Your company name'
          // disabled: employerGroups.length <= 0
     };

     const searchIconClicked = () => {
          if (typeof window != 'undefined' && searchInputText) {
               if (props.groupTypeToTarget === 'Agency') {
                    window.location.href = `/myagent/results/?q=${encodeURIComponent(searchInputText)}`;
               } else {
                    window.location.href = `/employee-benefits/company-search/results/?q=${encodeURIComponent(searchInputText)}`;
               }
          }
     };

     return (
          <div className="custom-search">
               {props.searchTitle && (
                    <div className="custom-search-title">
                         <p>
                              <strong>{props.searchTitle}</strong>
                         </p>
                    </div>
               )}
               <div className={`custom-search-input${employerGroups.length <= 0 ? ' loading-data-employer' : ''}`}>
                    {employerGroups.length <= 0 ? (
                         <PageLoadingData />
                    ) : (
                         <>
                              <Autosuggest
                                   suggestions={suggestions}
                                   onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                   onSuggestionsClearRequested={onSuggestionsClearRequested}
                                   getSuggestionValue={getSuggestionValue}
                                   renderSuggestion={renderSuggestion}
                                   onSuggestionHighlighted={onSuggestionHighlighted}
                                   inputProps={inputProps}
                              />
                              {props.searchIcon && (
                                   <div className={`search-icon ${searchInputText ? 'cursor-pointer' : ''}`} onClick={searchIconClicked}>
                                        <SvgIcon type="search" />
                                   </div>
                              )}
                         </>
                    )}
               </div>
          </div>
     );
};

const SearchWithEmployerGroups = (props) => {
     return <>{props.isPreview ? <PreviewEmployerGroupsSearching {...props} /> : <EmployerGroupsSearching {...props} />}</>;
};

class SearchWithFileData extends Component {
     constructor(props) {
          super(props);

          this.state = {
               error: null,
               isLoaded: false,
               items: [],
               suggestions: [],
               searchInputText: '',
               groupURLSelected: '',
               buttonClicked: false
          };
     }

     showResultBox = () => {
          this.setState({ buttonClicked: true });
     };

     hideResultBox = () => {
          this.setState({ buttonClicked: false });
     };

     componentDidMount() {
          if (typeof this.props.data != 'undefined' && this.props.data != null) {
               let dataUrl = this.props.isPreview ? this.props.data.fields.file.url : this.props.data.file.url;
               axios.get(dataUrl)
                    .then((res) => {
                         this.setState({ isLoaded: true, items: res.data });
                    })
                    .catch((error) => {
                         this.setState({ isLoaded: true, error });
                    });
          }
     }

     // Teach Autosuggest how to calculate suggestions for any given input value.
     getSuggestions = (value) => {
          const inputValue = value.trim().toLowerCase();
          const inputLength = inputValue.length;

          return inputLength == 0 ? [] : this.state.items.filter((item) => item.text.toLowerCase().includes(inputValue));
     };

     // When suggestion is clicked, Autosuggest needs to populate the input
     // based on the clicked suggestion. Teach Autosuggest how to calculate the
     // input value for every given suggestion.
     getSuggestionValue = (suggestion) => suggestion.text;

     // Use your imagination to render suggestions.
     renderSuggestion = (suggestion) => (
          <a href={suggestion.site} target="_blank">
               {suggestion.text}
          </a>
     );

     onChange = (event, { newValue }) => {
          this.setState({ searchInputText: newValue });
     };

     // Autosuggest will call this function every time you need to update suggestions.
     // You already implemented this logic above, so just use it.
     onSuggestionsFetchRequested = ({ value }) => {
          this.setState({ suggestions: this.getSuggestions(value) });
     };

     // Autosuggest will call this function every time you need to clear suggestions.
     onSuggestionsClearRequested = () => {
          this.setState({ suggestions: [] });
     };

     onSuggestionHighlighted = ({ suggestion }) => {
          if (suggestion) {
               this.setState({ groupURLSelected: suggestion.site });
          } else {
               this.setState({ groupURLSelected: '' });
          }
     };

     onKeyDown = (e) => {
          if (e.keyCode == '13') {
               if (typeof window != 'undefined' && this.state.groupURLSelected) {
                    window.open(this.state.groupURLSelected, '_blank');
               }
          }
     };

     searchInputToggle = () => this.setState({ buttonClicked: !this.state.buttonClicked });

     render() {
          const inputProps = {
               placeholder: this.props.inputPlaceholder,
               value: this.state.searchInputText,
               onChange: this.onChange,
               onKeyDown: this.onKeyDown,
               'aria-label': 'Your company name'
          };

          return this.props.searchButton ? (
               <>
                    <div className="custom-search-input">
                         <button className="dropbtn" type="button" onClick={this.searchInputToggle}>
                              {this.props.searchTitle && this.props.searchTitle}
                         </button>
                         <div className={`dropdown-content${this.state.buttonClicked ? ' open-search' : ''}`}>
                              <input
                                   type="text"
                                   className="search-query"
                                   aria-label="Search Query"
                                   value={this.state.searchInputText}
                                   onChange={(e) => this.setState({ searchInputText: e.target.value })}
                                   placeholder="Search.."
                                   onFocus={() => this.showResultBox()}
                              />
                              {this.state.items.length > 0 &&
                                   this.state.items.map((item, i) => {
                                        if (item.text.toLowerCase().includes(this.state.searchInputText.toLowerCase())) {
                                             return (
                                                  <p key={i}>
                                                       <a href={item.site} target="_blank" aria-label={item.text + ' Search'}>
                                                            {item.text}
                                                       </a>
                                                  </p>
                                             );
                                        }
                                   })}
                         </div>
                    </div>
               </>
          ) : (
               <div className="custom-search">
                    {this.props.searchTitle && (
                         <div className="custom-search-title">
                              <p>
                                   <strong>{this.props.searchTitle}</strong>
                              </p>
                         </div>
                    )}
                    <div className="custom-search-input">
                         <Autosuggest
                              suggestions={this.state.suggestions}
                              onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                              onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                              getSuggestionValue={this.getSuggestionValue}
                              renderSuggestion={this.renderSuggestion}
                              onSuggestionHighlighted={this.onSuggestionHighlighted}
                              inputProps={inputProps}
                         />
                         {this.props.searchIcon && (
                              <div className="search-icon">
                                   <SvgIcon type="search" />
                              </div>
                         )}
                    </div>
               </div>
          );
     }
}

const BlockSearch = (props) => {
     if (props.searchType === 'Wacky') {
          return <ContentfulBlockWackySearch {...props} />;
     }
     return <>{typeof props.data != 'undefined' && props.data != null ? <SearchWithFileData {...props} /> : <SearchWithEmployerGroups {...props} />}</>;
};

BlockSearch.propTypes = {
     isPreview: PropTypes.bool
};

function mapStateToProps(state) {
     return {
          isPreview: getIsPreviewStatus(state)
     };
}

const ContentfulBlockSearch = connect(mapStateToProps)(BlockSearch);

export default ContentfulBlockSearch;